
import { Component, Vue } from 'vue-property-decorator';
import { dispatchLogIn } from '@/store/main/actions';
import ResetPasswordModal from '@/components/Landing/ResetPasswordModal.vue';

@Component({
  components: {
    ResetPasswordModal,
  },
})
export default class Login extends Vue {
  public email: string = '';
  public password: string = '';
  public code: any = null;
  public formError: boolean = false;
  public loginError: boolean = false;
  public loadingState: any = {
    submit: false,
  };
  public showResetPasswordModal: boolean = false;

  public validateData() {
    if (!this.email || !this.password) {
      this.formError = true;
    } else {
      this.formError = false;
    }
    return !this.formError;
  }

  public handleOpenResetPassword() {
    this.$router.push('/recover-password');
    // this.showResetPasswordModal = true;
  }

  public handleCloseResetPassword() {
    this.showResetPasswordModal = false;
  }

  public async handleSubmit() {
    this.loginError = false;
    if (!await this.validateData()) {
      return null;
    }
    const result = await dispatchLogIn(this.$store, {
      username: this.email, password: this.password, code: this.code,
      timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    if (result) {
      this.$router.push('/dashboard');
    } else {
      this.loginError = true;
      // this.$toast.error(this.$t('Error').toString());
    }
  }
}
